<template>
  <div class="payables-table">
    <b-table
      id="payables-table"
      show-empty
      hover
      fixed
      class="payables-table__table"
      head-variant="light"
      stacked="md"
      :items="items"
      :fields="fields"
      :busy="isBusy"
    >
      <template v-slot:table-busy>
        <div class="spinner-payables">
          <b-spinner
            v-if="isBusy"
            style="width: 3rem; height: 3rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </div>
      </template>
      <template v-slot:thead-top="data">
        <b-tr v-bind:key="data.columns">
          <b-th variant="dark" :colspan="colspan">
            <span><strong>PAYABLES </strong></span>
          </b-th>
        </b-tr>
      </template>
      <template v-slot:cell(name)="row">
        <button class="button-link" @click="row.toggleDetails">
          {{ items[row.index].name }}
        </button>
      </template>
      <template #row-details="row">
        <b-card :key="row.index">
          <PayableLinesTable
            :payablesId="items[row.index]"
            :shP="shp"
            :rowIndex="row.index"
            @totalChanged="loadingTotals"
            @updateTotals="updateTotalsFromLineTable"
          />
        </b-card>
      </template>
      <template v-slot:cell(date)="row">
        <input
          type="date"
          readonly
          v-model="items[row.index].acctseed_date"
          :title="items[row.index].acctseed_date"
          class="table-input"
        />
      </template>
      <template v-slot:cell(due_date)="row">
        <input
          :readonly="items[row.index].acctseed_status != 'Waiting for Approval'"
          type="date"
          v-model="items[row.index].acctseed_due_date"
          :title="items[row.index].acctseed_due_date"
          class="table-input"
          @blur="
            sendToDueDate(
              items[row.index].acctseed_due_date,
              row.index,
              items[row.index].acctseed_status
            )
          "
        />
      </template>
      <template v-slot:cell(payee)="row">
        <AutoComplete
          :readonly="items[row.index].acctseed_status != 'Waiting for Approval'"
          v-model="row.item.acctseed_vendor"
          :suggestions="optionsPayee"
          @complete="getListByName($event)"
          @item-select="updatePayables($event, row.index)"
          field="name"
          class="datalist"
          :title="row.item.acctseed_vendor.name"
        />
      </template>
      <template v-slot:cell(payee_reference)="row">
        <input
          :readonly="items[row.index].acctseed_status != 'Waiting for Approval'"
          type="text"
          v-model="items[row.index].acctseed_payee_reference"
          :title="items[row.index].acctseed_payee_reference"
          class="table-input"
          @blur="
            sendToPayeeReference(
              items[row.index].acctseed_payee_reference,
              row.index,
              items[row.index].acctseed_status
            )
          "
        />
      </template>

      <template v-slot:cell(status)="row">
        <input
          type="text"
          readonly
          v-model="items[row.index].acctseed_status"
          :title="items[row.index].acctseed_status"
          class="table-input"
        />
      </template>
      <template v-slot:cell(total)="row">
        <b-overlay
          :show="loadingTotal"
          rounded="sm"
          spinner-variant="info"
          spinner-small
        >
          <input
            type="text"
            readonly
            v-model="items[row.index].acctseed_total"
            :title="items[row.index].acctseed_total"
            class="table-input"
          />
        </b-overlay>
      </template>
    </b-table>
  </div>
</template>

<script>
import fieldsPayablesTable from "@/utils/payablesTable";
import dayjs from "dayjs";
import PayableLinesTable from "./PayableLinesTable";
import AutoComplete from "primevue/autocomplete";

export default {
  name: "PayablesTable",
  components: {
    PayableLinesTable,
    AutoComplete,
  },
  props: {
    shp: {
      type: Object,
      required: true,
    },
    payableId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      fields: null,
      colspan: 7,
      isBusy: false,
      loadId: null,
      payablesId: null,
      optionsPayee: [],
      loadingTotal: false,
    };
  },
  created() {
    this.fields = fieldsPayablesTable;
    for (let item in this.items) {
      this.items[item].due_date = dayjs(this.items[item].due_date).format(
        "YYYY-MM-DD"
      );
      this.items[item].date = dayjs(this.items[item].date).format("YYYY-MM-DD");
    }
    this.showDataPayables();
  },
  methods: {
    loadingTotals() {
      this.loadingTotal = true;
    },
    async updateTotalsFromLineTable(rowIndex) {
      let response = await this.$store.dispatch(
        "crmPayables/getDataPayables",
        this.loadId
      );
      let total = response.data[rowIndex].acctseed_total;
      this.items[rowIndex].acctseed_total = total;
      this.loadingTotal = false;
    },
    async showDataPayables() {
      // this.isBusy = true;
      this.loadId = this.shp.salesforce_id;
      let response = await this.$store.dispatch(
        "crmPayables/getDataPayables",
        this.loadId
      );
      this.items = response.data;
      // this.isBusy = false;
    },
    async sendToDueDate(dueDate, index, status) {
      if (status == "Waiting for Approval") {
        await this.$store.dispatch("crmPayables/updatePayables", {
          payable_crm_id: this.items[index].id,
          payable_db_id: this.payableId,
          body: {
            acctseed_due_date: dueDate,
          },
        });
        // this.showDataPayables();
      }
    },
    async sendToPayeeReference(payeeReference, index, status) {
      if (status == "Waiting for Approval") {
        await this.$store.dispatch("crmPayables/updatePayables", {
          payable_crm_id: this.items[index].id,
          payable_db_id: this.payableId,
          body: {
            acctseed_payee_reference: payeeReference,
          },
        });
        this.showDataPayables();
      }
    },
    async updatePayables(payee, index) {
      let payeeObject = payee.value;
      if (payeeObject != null) {
        let acctseedVendor = {};
        acctseedVendor.name = payeeObject.name;
        acctseedVendor.id = payeeObject.id;

        await this.$store.dispatch("crmPayables/updatePayables", {
          payable_crm_id: this.items[index].id,
          payable_db_id: this.payableId,
          body: {
            acctseed_vendor: acctseedVendor,
          },
        });
        this.showDataPayables();
      }
    },
    async getListByName(value) {
      let input = value.query;
      let optionSelected = this.optionsPayee.find(
        (option) => option.name == input
      );
      if (input != "" && input != null && optionSelected == undefined) {
        let response = await this.$store.dispatch(
          "account/getListByName",
          input
        );
        this.optionsPayee = response.data;
        this.$store.commit("crmPayables/setPayeeObject", response.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payables-table {
  margin-top: 30px;

  &__table {
    @include box-shadow;
  }
}

.table .thead-light th {
  color: $color-light;
  background-color: $color-background-dark;
  border: none;
  border-radius: 6px 6px 0px 0px;
  padding: 20px 10px;
}

.b-table {
  cursor: pointer;
  border-radius: 6px;
  font-size: 11px;
}

.table-input {
  width: 100%;
  border: none;
  outline: none;
  color: inherit;
  border-radius: 6px;
  padding: 5px 3px;
}

.button-link {
  width: 100%;
  height: 100%;
  padding: 5px 3px;
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: $color-white;
  color: $color-link;

  &:hover {
    text-decoration: underline;
  }
}

.button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.spinner-payables {
  display: flex;
  justify-content: center;
}

.datalist {
  height: 28px;
}

::v-deep .p-autocomplete-input {
  width: 100%;
  font-size: 10px;
  border-radius: 6px;
  border: none;
  outline: none;
}

::v-deep .p-autocomplete-items {
  font-size: 10px;
}
</style>
