const fieldsPayableLinesTable = [
  {
    key: "name",
    label: "NAME"
  },
  {
    key: "product",
    label: "PRODUCT"
  },
  {
    key: "expense_gi_account",
    label: "Expense GI Account"
  },
  {
    key: "amount",
    label: "AMOUNT"
  }
];

export default fieldsPayableLinesTable;
