<template>
  <div class="happy-path">
    <h4 class="text-center">
      <strong>HAPPY PATH </strong>
    </h4>
    <hr />
    <div class="spinner-happy-path">
      <b-spinner
        v-if="isLoading"
        style="width: 3rem; height: 3rem"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </div>
    <div class="happy-path__cards" v-if="!isLoading">
      <div class="left-card">
        <div class="left-card__carrier-name">
          <label>Carrier Name:</label>
          <b-form-input
            v-model="carrierName"
            size="sm"
            placeholder="Carrier Name"
            :disabled="true"
          ></b-form-input>
        </div>
        <div class="left-card__dates">
          <div class="invoice-date">
            <label>Invoice Date</label>
            <b-form-input
              v-model="invoiceDate"
              size="sm"
              placeholder="Invoice Date"
              :disabled="true"
            ></b-form-input>
          </div>
          <div class="due-date">
            <label>Due Date</label>
            <b-form-input
              v-model="dueDate"
              size="sm"
              placeholder="Due Date"
              :disabled="true"
            ></b-form-input>
          </div>
        </div>
        <div class="left-card__invoice-number">
          <label>Invoice Number:</label>
          <b-form-input
            v-model="invoiceNumber"
            size="sm"
            placeholder="Invoice Number"
            :disabled="true"
          ></b-form-input>
        </div>
      </div>
      <div class="right-card">
        <div class="left-card__dates">
          <div class="load-number">
            <label>Load Number:</label>
            <b-form-input
              v-model="loadNumber"
              size="sm"
              placeholder="Load Number"
              :disabled="true"
            ></b-form-input>
          </div>
          <div class="invoice-amount">
            <label>Invoice Amount:</label>
            <b-form-input
              v-model="invoiceAmount"
              size="sm"
              placeholder="Invoice Amount"
              :disabled="true"
            ></b-form-input>
          </div>
        </div>
        <div class="dispatcher-name">
          <label>Dispatcher Name:</label>
          <b-form-input
            v-model="dispatcherName"
            size="sm"
            placeholder="Dispatcher Name"
            :disabled="true"
          ></b-form-input>
        </div>
        <div class="dispatcher-email">
          <label>Dispatcher Email:</label>
          <b-form-input
            v-model="dispatcherEmail"
            size="sm"
            placeholder="Dispatcher Email"
            :disabled="true"
          ></b-form-input>
        </div>
      </div>
    </div>
    <div class="bottom-card__column">
      <div class="bottom-card__row">
        <div class="bottom-card__input payee-email">
          <label>Payee Email:</label>
          <b-form-input
            v-model="payeeEmail"
            size="sm"
            placeholder="Payee Email"
            :disabled="true"
          ></b-form-input>
        </div>
        <div class="bottom-card__input">
          <label>Payee Address:</label>
          <b-form-input
            v-model="payeeAddress"
            size="sm"
            placeholder="Payee Address"
            :disabled="true"
          ></b-form-input>
        </div>
      </div>
      <div class="bottom-card__row">
        <div class="bottom-card__input">
          <label>Payee Name: </label>
          <b-form-input
            v-model="payeeName"
            size="sm"
            placeholder="Payee Name"
            :disabled="true"
          ></b-form-input>
        </div>
        <div class="bottom-card__input routing-number">
          <label>Routing Number:</label>
          <b-form-input
            v-model="routingNumber"
            size="sm"
            placeholder="Routing Number"
            :disabled="true"
          ></b-form-input>
        </div>
      </div>
      <div class="bottom-card__row">
        <div class="bottom-card__input">
          <label>Payee Information:</label>
          <b-form-input
            v-model="payeeInformation"
            size="sm"
            placeholder="Payee Information"
            :disabled="true"
          ></b-form-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HappyPath",
  props: {
    shp: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      carrierName: "",
      invoiceDate: null,
      dueDate: null,
      invoiceNumber: "",
      loadNumber: "",
      invoiceAmount: "",
      dispatcherEmail: "",
      dispatcherName: "",
      payeeEmail: "",
      routingNumber: "",
      payeeName: "",
      payeeAddress: "",
      payeeInformation: "",
      isLoading: false,
      dbId: null
    };
  },
  async created() {
    await this.showInfoDispatcher();
    await this.showInfoPayables();
  },
  methods: {
    async showInfoDispatcher() {
      this.isLoading = true;
      let response = await this.$store.dispatch(
        "crmLoads/getDispatcherInfo",
        this.shp.salesforce_id
      );
      this.dispatcherEmail = response.data.dispatcher_email;
      this.dispatcherName = response.data.created_by;
      this.isLoading = false;
    },
    async showInfoPayables() {
      let loadId = this.shp.id;
      this.isLoading = true;
      let response = await this.$store.dispatch("dbPayables/getPayables", {
        load_id: loadId,
        email_id: this.$route.params.id
      });
      let infoPayable = response.extraction;
      this.carrierName = infoPayable.carrier_name;
      this.invoiceDate = infoPayable.invoice_date;
      this.dueDate = infoPayable.due_date;
      this.invoiceNumber = infoPayable.invoice_number;
      this.invoiceAmount = infoPayable.invoice_amount;
      this.payeeAddress = infoPayable.payee_address;
      this.payeeInformation = infoPayable.payee_information;
      this.payeeEmail = infoPayable.payee_email;
      this.payeeName = infoPayable.payee_name;
      this.routingNumber = infoPayable.routing_number;
      this.loadNumber = infoPayable.load_number;
      this.isLoading = false;
      this.$emit("getPayableId", response.id);
    }
  }
};
</script>

<style lang="scss" scoped>
%inner-card {
  width: 49%;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 5px;
  padding: 10px;
  background-color: #fafafa;
  box-shadow: 0 1.5px 2px 0 rgba($color-black, 0.14),
    0 1.5px 1.5px -2px rgba($color-black, 0.2),
    0 1px 2px 0 rgba($color-black, 0.12);
}

%left-input {
  width: 50%;
  margin-right: 5px;
}

%right-input {
  width: 50%;
  margin-left: 5px;
}

%row-flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.happy-path {
  margin: 0px 5px 0px 0px;
  padding-top: 0px;
  @include card;
  padding-bottom: 20px;
  width: 100%;

  label {
    margin: 0px;
    font-size: 12px;
  }

  &__cards {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.left-card {
  @extend %inner-card;
  min-width: 350px;
  margin-top: 10px;

  &__carrier-name {
    width: 100%;
  }

  &__dates {
    @extend %row-flex;
    margin-top: 10px;
  }

  &__invoice-number {
    margin-top: 10px;
  }
}

.invoice-date {
  @extend %left-input;
}

.due-date {
  @extend %right-input;
}

.right-card {
  @extend %inner-card;
  min-width: 350px;
  margin-top: 10px;

  &__row-one {
    @extend %row-flex;
  }

  &__row-two {
    @extend %row-flex;
    margin-top: 10px;
  }

  &__row-three {
    @extend %row-flex;
    margin-top: 10px;
  }
}

.load-number {
  @extend %left-input;
}

.invoice-amount {
  @extend %left-input;
  margin-left: 5px;
  margin-right: 0px;
}

.dispatcher-email {
  width: 100%;
}

.dispatcher-name {
  width: 100%;
}

.search-with-shp {
  @include secondary-button;
}

.bottom-card {
  @extend %inner-card;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &__input {
    width: 100%;
    margin: 0px 5px;
    flex-direction: row;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }
  &__row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
}

.button {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.spinner-happy-path {
  display: flex;
  justify-content: center;
}

::v-deep .form-control {
  &:hover {
    box-shadow: 0 6px 8px 0 rgba($color-black, 0.14),
      0 3px 3px -2px rgba($color-black, 0.2),
      0 1px 8px 0 rgba($color-black, 0.12);
  }
}
</style>
