<template>
  <div>
    <div>
      <b-button variant="link" v-b-modal="'history-table-modal'"
        >Show history</b-button
      >
    </div>
    <b-modal id="history-table-modal" hide-footer hide-header size="lg">
      <div class="d-block text-center">
        <div>
          <b-table
            sticky-header="400px"
            style="font-size: 0.8rem"
            show-empty
            hover
            responsive
            striped
            head-variant="light"
            :items="items"
            :fields="fields"
          >
            <template v-slot:thead-top="data">
              <b-tr v-bind:key="data.columns">
                <b-th variant="dark" :colspan="6">
                  <span><strong>HISTORY</strong></span>
                </b-th>
              </b-tr>
            </template>
            <template v-slot:cell(payable_id)="row">
              {{ items[row.index].payable_id }}
            </template>
            <template v-slot:cell(username)="row">
              {{ items[row.index].username }}
            </template>
            <template v-slot:cell(action)="row">
              {{ items[row.index].action }}
            </template>
            <template v-slot:cell(type)="row">
              {{ items[row.index].type }}
            </template>
            <template v-slot:cell(created_at)="row">
              {{ items[row.index].created_at }}
            </template>
            <template #cell(description)="row">
              <b-button pill size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? "Hide" : "Show" }}
              </b-button>
            </template>
            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"
                    ><b>Description: </b></b-col
                  >
                  <b-col>
                    <div
                      v-for="(value, key) in items[row.index].description"
                      :key="key"
                    >
                      {{
                        typeof value === "object"
                          ? "Payee: " + value.name
                          : key + ": " + value
                      }}
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
        </div>
      </div>
      <div class="modal-close-button">
        <b-button
          class="mt-3"
          pill
          variant="info"
          @click="$bvModal.hide('history-table-modal')"
          >OK</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "HistoriesTable",
  // All this props are the filters for send to api and all of them are not required
  props: {
    skip: {
      type: Number,
    },
    limit: {
      type: Number,
    },
    type: {
      type: String,
    },
    action: {
      type: String,
    },
    payableId: {
      type: Number,
    },
    username: {
      type: String,
    },
  },

  data() {
    return {
      items: [],
      fields: [
        "payable_id",
        "username",
        "action",
        "type",
        "created_at",
        "description",
      ],
    };
  },
  created() {
    this.getHistory();
  },

  methods: {
    async getHistory() {
      let search = {
        skip: 0,
        limit: 200, // Limit to show
        payable_id: this.payableId, // On 0 display history for all payable_id
      };

      let response = await this.$store.dispatch("dbSalesforceHistory/getHistory", search);
      this.items = response;

      for (let item in this.items) {
        this.items[item].created_at = dayjs(this.items[item].created_at).format(
          "YYYY-MM-DD"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table .thead-light th {
  color: $color-light;
  background-color: $color-background-dark;
  border: none;
  border-radius: 6px 6px 0px 0px;
}

.modal-close-button {
  display: flex;
  justify-content: center;
  justify-content: flex-end;
  padding: 10px;
}

.modal-dialog {
  width: 460px;
  height: 650px !important;
}
</style>
