<template>
  <div class="receivers-message">
    <!--INPUT FROM REPLY-->
    <div class="receivers-message__container">
      <strong>From:</strong>
      <InputText
        type="text"
        v-model="infoSend.sender"
        placeholder="Enter email"
        disabled
      />
    </div>
    <!--INPUT TO REPLY-->
    <div class="receivers-message__container">
      <strong>To:</strong>
      <Chips
        v-model="infoSend.receiver"
        separator=","
        placeholder="Enter emails separated comma or enter"
      />
    </div>
    <!--INPUT CARBON COPY REPLY-->
    <div class="receivers-message__container">
      <strong>Cc:</strong>
      <Chips
        v-model="infoSend.cc"
        separator=","
        placeholder="Enter emails separated comma or enter"
      />
    </div>
    <!--INPUT BLIND CARBON COPY REPLY-->
    <div class="receivers-message__container">
      <strong>Bcc:</strong>
      <Chips
        v-model="infoSend.bcc"
        separator=","
        placeholder="Enter emails separated comma or enter"
      />
    </div>
    <!--INPUT SUBJECT-->
    <div class="receivers-message__container">
      <strong>Subject:</strong>
      <InputText
        type="text"
        v-model="infoSend.emailSubject"
        placeholder="Enter a Subject"
        disabled
      />
    </div>
    <!--FileUpload-->
    <div class="upload-files-input">
      <FileUpload
        :auto="false"
        chooseLabel="Choose files"
        @select="filesChange"
        @clear="clearFile"
        :showUploadButton="false"
        :multiple="true"
      >
        <template #empty>
          <p>Drag and drop files to here to upload.</p>
        </template>
      </FileUpload>
    </div>
  </div>
</template>

<script>
import Chips from "primevue/chips";
import InputText from "primevue/inputtext";
import FileUpload from "primevue/fileupload";

export default {
  name: "Receivers",
  props: {
    emailType: {
      required: true,
      type: String,
    },
    emailInfo: {
      required: true,
      type: Object,
    },
  },
  components: {
    Chips,
    InputText,
    FileUpload,
  },
  watch: {
    infoSend: {
      deep: true,
      handler(valor) {
        this.handlerSendFather(valor);
      },
    },
    emailInfo: {
      deep: true,
      handler(value) {
        this.handlerchange(value);
      },
    },
  },
  data() {
    return {
      infoSend: {
        sender: "carrierpay@shipwithglt.com",
        receiver: [],
        cc: [],
        bcc: [],
        emailSubject: null,
        files: []
      },
    };
  },
  methods: {
    handlerchange(value) {
      this.infoSend.emailSubject = value.subject;
    },
    handlerSendFather(valor) {
      this.$emit("custom-change", { info: valor, type: this.emailType });
    },
    filesChange(fileList) {
      fileList.files.forEach((file) => {
        this.infoSend.files.push(file);
      });
    },
    clearFile() {
      this.infoSend.files = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.receivers-message {
  width: 100%;
  padding: 20px 0px;
  &__from {
    margin: 0px;
    margin-left: 6px;
    padding: 0px 3px;
  }

  &__container {
    padding: 10px;
    display: flex;
    align-items: baseline;
  }

  &__input {
    margin-left: 10px;
    font-size: 14px;
    border: none;
    background-color: white !important;

    &:focus {
      box-shadow: none;
    }
  }
}

::v-deep .p-chips {
  width: 100%;
}

::v-deep .p-chips-multiple-container {
  border: none;
  width: 100%;
  padding: 0;
  margin-left: 20px;
}

::v-deep .p-inputtext {
  border: none;
  width: 100%;
  padding: 7px;
  margin-left: 20px;
}

::v-deep .p-fileupload-files{
  font-size: 12px;
  .p-button{
    display: none;
  }
}
</style>
