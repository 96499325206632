<template>
  <div class="infoemail-attachments">
    <template v-if="!bucketFiles">
      <b-spinner
        label="Spinning"
        variant="primary"
        class="mb-5 mt-5"
      ></b-spinner>
    </template>
    <template v-else>
      <div
        v-for="(file, index) in bucketFiles"
        :key="'images.name' + index"
        class="file-container"
      >
        <img
          v-if="file.type.search('image') !== -1"
          src="@/assets/images/logo-image.png"
          @click="showPdf(index)"
          alt=""
          class="file-container__image-icon"
        />
        <img
          v-else
          src="@/assets/images/logo-pdf.png"
          @click="showPdf(index)"
          alt=""
        />
        <div class="file-container__name">
          {{ file.name }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InfoEmailAttachments",
  props: {
    bucketFiles: {
      required: false,
      type: Array,
    },
  },
  data() {
    return {
      files: [],
      fileUploadInstance: null,
      file: [],
      hasFiles: false,
      resetButton: false,
      IdCreateEmail: null,
      count: 0,
      filesCreated: [],
      isDisabled: true,
      options: [],
      fileIdIndex: null,
      tabNumber: this.load,
    };
  },
  computed: {
    disabled() {
      return this.isDisabled;
    },
    Choose() {
      return "Add Files";
    },
    Cancel() {
      return "Cancel";
    },
    Upload() {
      return "Upload";
    },
  },
  watch: {
    bucketFiles() {
      this.files = [...this.bucketFiles];
      this.showPdf(0);
    },
  },
  methods: {
    async handleSelect(event) {
      this.file.push(event.files[this.count]);
      let length = this.file.length;
      if (this.file.length == 1) {
        await this.createEmail();
      }
      let tabSelected = this.$store.getters["dbFile/showTabSelected"];
      this.file[length - 1].tab = tabSelected;
      this.$store.commit("dbFile/setHandleSelected", this.file[length - 1]);
      let body = {};
      body.name = this.file[length - 1].name;
      body.email_id = this.idCreateEmail;
      body.pages = [];
      this.count += 1;
      this.createFile(body);
    },
    showPdf(index) {
      if (this.files[index].type.search("image") !== -1) {
        this.$emit("getPdfSrc", {
          src: this.files[index],
          type: "image",
        });
      } else {
        this.files[index].arrayBuffer().then((data) => {
          const uint8Arr = new Uint8Array(data);
          this.$emit("getPdfSrc", {
            src: uint8Arr,
            file: this.files[index],
            type: "PDF",
          });
        });
      }
      this.fileIdIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .p-fileupload-content {
  display: none;
}

::v-deep .p-fileupload .p-fileupload-buttonbar {
  padding: 0;
  border: none;
  .p-button {
    @include primary-button;
  }
}

.infoemail-attachments {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 20px;
}

.file-container {
  width: 90px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  position: relative;
  padding: 5px 3px;
  padding-bottom: 0px;
  margin-top: 20px;
  cursor: pointer;

  &--selected {
    background: var(--secondary-gray-outline) 0% 0% no-repeat padding-box;
    border: 1px solid #c3c5c9;
    background: #f6f6f6 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  &:not(:first-child) {
    margin-top: 14px;
  }

  & > img {
    width: 60px;
    object-fit: cover;
  }

  & > span {
    overflow-wrap: break-word;
    width: 100%;
  }
  &__name {
    margin: 0px;
    word-break: break-all;
  }
  &__image-icon{
    width: 65% !important;
  }
}
</style>
