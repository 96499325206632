<template>
  <div class="form" v-if="thereIsData">
    <h4 class="form__title text-center">
      <strong> SUBJECT: </strong>{{ this.emailInformation.subject }}
    </h4>
    <hr />
    <div class="form__header-options">
      <b-button pill size="sm" class="show-email" v-b-toggle.collapse-email
        >Show Email <i class="fas fa-envelope"></i
      ></b-button>
      <b-button
        pill
        size="sm"
        variant="danger"
        class="delete-process"
        @click="deleteEmail()"
        >Delete <i class="fas fa-trash"></i
      ></b-button>
    </div>
    <div>
      <Button
        v-for="(tag, index) in tags"
        :key="index"
        icon="fas fa-tag"
        :label="tag.name"
        :style="'color: ' + tag.color + ';margin:20px 5px 5px 0px;'"
        class="tag-button p-button-sm p-button-outlined tag-header"
      >
      </Button>
    </div>
    <div class="form__email-content">
      <b-collapse id="collapse-email">
        <b-card>
          <p v-if="this.emailText.text == '' ? false : true" class="card-text">
            Text : {{ this.emailText.text }}
          </p>
          <span v-html="this.emailBody.data"></span>
          <span :v-html="this.emailInformation.subject"></span>
        </b-card>
      </b-collapse>
    </div>
    <b-card class="form__tabs" no-body v-show="isFormDisable">
      <b-tabs card lazy>
        <template v-slot:empty>
          <div>There are no open tabs</div>
        </template>
        <div class="modal-show-button"></div>
        <div v-for="(shpSelected, index) in shpList" :key="index">
          <template>
            <b-tab
              :title="shpSelected.shp"
              @click="changeLoadInfo(shpSelected)"
              :key="index"
            >
              <div
                v-if="payableIdReady && payableId != 0"
                class="histories-container"
              >
                <HistoriesTable
                  :skip="0"
                  :limit="1000"
                  :type="''"
                  :action="''"
                  :payableId="payableId"
                  :username="''"
                />
              </div>
              <div class="process-info">
                <HappyPath
                  :shp="shpSelected"
                  @getPayableId="getPayableId($event)"
                />
                <PayablesTable :shp="shpSelected" :payableId="payableId" />
              </div>
            </b-tab>
          </template>
        </div>
      </b-tabs>
    </b-card>
    <hr id="scroll-top-email" />
    <EmailResponse v-show="isDisabled" :emailInformation="emailInformation" />
  </div>
</template>

<script>
import HappyPath from "./HappyPath";
import PayablesTable from "./PayablesTable";
import EmailResponse from "./EmailResponse";
import HistoriesTable from "@/components/HistoriesTable";
import Button from "primevue/button";

export default {
  name: "Form",
  components: {
    HappyPath,
    PayablesTable,
    EmailResponse,
    HistoriesTable,
    Button,
  },
  data() {
    return {
      labelOptions: null,
      selectedLabel: [],
      thereIsData: false,
      shpList: [],
      bucketFiles: [],
      emailInformation: {},
      emailText: {},
      emailBody: {},
      payableId: 0,
      payableIdReady: false,
      tagsByEmailId: null,
      tags: {},
      isDisabled: true,
      isFormDisable: true,
    };
  },
  watch: {
    bucketFiles() {
      this.changeLoadInfo(this.shpList[0]);
      this.payableIdReady = false;
      this.payableIdReady = true;
    },
  },
  created() {
    let path = localStorage.getItem("lastPath");
    if (path.match("posted")) {
      this.isDisabled = false;
    }
  },
  mounted() {
    this.getTagsEmail();
    this.getDbLoads();
    this.getEmailInformationByEmailId();
  },
  methods: {
    getPayableId(id) {
      this.payableId = id;
      //This is because the first time that we load the view
      //Start value in "false" and then "true" after the payableId have value
      if (this.payableId != 0) {
        this.payableIdReady = false;
        this.payableIdReady = true;
      }
    },
    /* Function to get all the saved load  */
    async getDbLoads() {
      let response = await this.$store.dispatch("dbLoads/getLoads", {
        email_id: this.$route.params.id,
      });
      this.shpList = response;
      this.getLoadsFiles(response);
    },
    async getTagsByEmailId() {
      let response = await this.$store.dispatch(
        "emails/getEmailsById",
        this.$route.params.id
      );
      this.tagsByEmailId = response;
    },
    async getTagsEmail() {
      let response = await this.$store.dispatch("dbTags/getTags");
      this.labelOptions = response.data;

      this.thereIsData = true;

      await this.getTagsByEmailId();

      for (let i in this.tagsByEmailId) {
        this.selectedLabel[i] = [];
        for (let j in this.labelOptions) {
          for (let tag in this.tagsByEmailId.tags) {
            if (
              this.tagsByEmailId.tags[tag].tag_id == this.labelOptions[j].id
            ) {
              this.selectedLabel[i].push(this.labelOptions[j]);
            }
          }
        }
      }
      this.tags = this.selectedLabel.tags;
    },
    async getLoadsFiles(loadsInfo) {
      let bucket = [];
      for (let load of loadsInfo) {
        let data = {
          email_id: this.$route.params.id,
          load_id: load.id,
        };
        let response = await this.$store.dispatch("dbFile/getDbFiles", data);
        response.forEach(async (item) => {
          let bucketData = {
            path: item.path,
            name: item.name,
            loadId: load.id,
          };
          let bucketResponse = await this.$store.dispatch(
            "bucket/getFilesFromBucket",
            bucketData
          );
          bucket.push(bucketResponse);
        });
      }
      this.changeLoadInfo(this.shpList[0]);
      this.bucketFiles = bucket;
    },

    async changeLoadInfo(loadInfo) {
      let bucketFiles = this.bucketFiles.filter(
        (file) => file.loadId === loadInfo.id
      );
      this.$emit("renderBucketFiles", bucketFiles);
      this.payableIdReady = false;
    },

    async getEmailInformationByEmailId() {
      let response = await this.$store.dispatch(
        "dbFile/getEmailInformationByEmailId",
        this.$route.params.id
      );
      this.emailInformation = response.data;
      let path = localStorage.getItem("lastPath");
      if (path.match("pending")) {
        if (this.emailInformation.status_email_id == 5) {
          this.isFormDisable = false;
          this.$emit("statusPending", this.emailInformation.status_email_id);
        }
      }
      this.getEmailContent();
    },

    async getEmailContent() {
      if (this.emailInformation.uid_msg != null) {
        let params = {};
        params.uid_msg = this.emailInformation.uid_msg;
        let response = await this.$store.dispatch(
          "emails/getEmailsContent",
          params
        );
        this.emailText = response;
        this.getEmailFileHtml();
      }
    },
    async getEmailFileHtml() {
      let params = {};
      if (this.emailText.path != null || this.emailText.path != "") {
        params.path = this.emailText.path;
        let response = await this.$store.dispatch(
          "dbFile/getEmailFileHtml",
          params
        );
        this.emailBody = response;
      }
    },
    async deleteEmail() {
      this.swal({
        title: "Are you sure?",
        text: "After deleting the email, you will not be able to recover it!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await this.$store.dispatch(
            "emails/deleteEmails",
            this.$route.params.id
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin-left: 5px;
  margin-bottom: 50px;

  &__header-options {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__email-content {
    margin-top: 20px;
  }

  &__tabs {
    margin-top: 20px;
  }

  &__submit {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.histories-container {
  display: flex;
  justify-content: center;
  justify-content: flex-end;
  padding: 10px;
}

.card-body {
  padding-top: 0px;
}

.show-email {
  @include primary-button;
  margin-right: 10px;
}

.delete-process {
  margin: 0px 10px;
  @include box-shadow;
}

.label-options {
  margin-left: 10px;
}
</style>
