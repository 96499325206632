const fieldsPayablesTable = [
  {
    key: "name",
    label: "NAME"
  },
  {
    key: "date",
    label: "DATE"
  },
  {
    key: "due_date",
    label: "DUE DATE"
  },
  {
    key: "payee",
    label: "PAYEE"
  },
  {
    key: "payee_reference",
    label: "PAYEE REFERENCE"
  },
  {
    key: "status",
    label: "STATUS"
  },
  {
    key: "total",
    label: "TOTAL"
  }
];

export default fieldsPayablesTable;
