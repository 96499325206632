<template>
  <div id="signature" v-if="thereIsData">
    <table>
      <tr>
        <td style="padding: 10px">
          <img
            src="../../../../../assets/images/GLT-LOGISTICS-(TRADEMARK).png"
            width="250"
            height="250"
            id="signature-image"
          />
        </td>
        <td style="padding: 10px">
          <table>
            <tr>
              <td>
                <input
                  type="text"
                  id="signature-name"
                  v-model="signature.name"
                  name="name"
                  class="signature-name"
                  placeholder="Name"
                  autocomplete="off"
                />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  id="signature-position"
                  v-model="signature.position"
                  name="position"
                  class="signature-position"
                  placeholder="Position"
                  autocomplete="off"
                />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  id="signature-phone"
                  name="phone"
                  class="signature-phone"
                  v-model="signature.phone"
                  placeholder="Phone"
                  autocomplete="off"
                />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  id="signature-fax"
                  name="fax"
                  class="signature-fax"
                  v-model="signature.fax"
                  placeholder="Fax"
                  autocomplete="off"
                />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  id="signature-address"
                  name="address"
                  class="signature-address"
                  v-model="signature.address"
                  placeholder="Address"
                  autocomplete="off"
                />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  id="signature-city"
                  name="city"
                  class="signature-city"
                  v-model="signature.city"
                  placeholder="Fax"
                  autocomplete="off"
                />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  id="signature-email"
                  name="email"
                  class="signature-email"
                  v-model="signature.email"
                  placeholder="email@shipwithglt.com"
                  autocomplete="off"
                />
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "Signature",
  data() {
    return {
      thereIsData: false,
      signature: {
        name: "",
        position: "AP TEAM",
        phone: "Ph: (305) 726-0275 ext. 546",
        fax: "Fax: (866) 906-0749",
        city: "Miami Springs, FL 33166",
        email: "email@shipwithglt.com",
        address: "10 Canal Street #318",
      },
      signatureHtml: `<table><tr><td style='padding: 10px'><img src='https://shipwithglt.com/wp-content/uploads/2018/09/GLT-LOGISTICS-1.svg' width='250' height='250'/></td><td style="padding: 10px"><table><tr><td><span style="border: none; outline: none; font-weight: bold; color: black; text-transform: uppercase; font-size: 13px;">signature-name</span></td></tr><tr><td><span style="border: none; outline: none; font-weight: bold; color: black; font-size: 13px;">signature-position</span></td></tr><tr><td><span style="border: none; outline: none; color: #635e5e; font-size: 10.5px;">signature-phone</span></td></tr><tr><td><span style="border: none; outline: none; color: #635e5e; font-size: 10.5px;">signature-fax</span></td></tr><tr><td><span style="font-size: 10.5px; color: #635e5e">10 Canal<sup style="font-size: 8px">nd</sup> Street.  #318</span></td></tr><tr><td><span style="border: none; outline: none; color: #635e5e; font-size: 10.5px;">signature-city</span></td></tr><tr><td><span style="border: none; outline: none; color: #1155cc; font-size: 10.5px; text-decoration: underline;">signature-email</span></td></tr></table></td></tr></table>`,
    };
  },
  watch: {
    signature: {
      async handler() {
        let signatureResponse = await this.getData();
        this.$emit("input", signatureResponse);
      },
      deep: true,
    },
  },
  async created() {
    let loggedUserId = JSON.parse(
      this.$store.getters["login/showUserInfo"].sub
    ).id;
    let result = await this.$store.dispatch("users/getUserById", loggedUserId);
    this.signature.name = result.data.name + " " + result.data.lastname;
    this.signature.email = result.data.email;
    this.thereIsData = true;
  },
  async mounted() {
    await this.sleep(200);
    let signatureResponse = await this.getData();
    this.$emit("input", signatureResponse);
  },
  methods: {
    async getData() {
      let signatureHtmlCopy = await this.signatureHtml;
      signatureHtmlCopy = await signatureHtmlCopy.replace(
        "signature-name",
        this.signature.name.toUpperCase()
      );
      signatureHtmlCopy = await signatureHtmlCopy.replace(
        "signature-position",
        this.signature.position
      );
      signatureHtmlCopy = await signatureHtmlCopy.replace(
        "signature-phone",
        this.signature.phone
      );
      signatureHtmlCopy = await signatureHtmlCopy.replace(
        "signature-fax",
        this.signature.fax
      );
      signatureHtmlCopy = await signatureHtmlCopy.replace(
        "signature-city",
        this.signature.city
      );
      signatureHtmlCopy = await signatureHtmlCopy.replace(
        "signature-email",
        this.signature.email
      );
      return signatureHtmlCopy;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style lang="scss" scoped>
%signature-data {
  border: none;
  outline: none;
  color: #635e5e;
  font-size: 10.5px;
}

.signature-name {
  border: none;
  outline: none;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  font-size: 13px;
}

.signature-position {
  border: none;
  outline: none;
  font-weight: bold;
  color: black;
  font-size: 13px;
}

.signature-phone,
.signature-fax,
.signature-city,
.signature-address {
  @extend %signature-data;
}

.signature-email {
  border: none;
  outline: none;
  color: #1155cc;
  font-size: 10.5px;
  text-decoration: underline;
}
</style>
