<template>
  <div class="pdf-viewer">
    <template v-if="src || imgSrc">
      <div
        class="pdf-viewer__pdf"
        v-for="(page, index) in numPages"
        :key="index"
      >
        <pdf v-if="src !== null" :src="src" :page="index + 1"> </pdf>
        <img :src="imgSrc" alt="" class="pdf-viewer__img"/>
      </div>
    </template>
    <div v-else class="spinner-container">
      <b-spinner
        label="Spinning"
        variant="primary"
        class="mb-5 mt-5"
        style="width: 5rem; height: 5rem"
      ></b-spinner>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";

export default {
  name: "InfoEmailPdfViewer",
  props: {
    pdfSrc: {
      type: Object,
      required: false
    }
  },
  components: {
    pdf
  },
  data() {
    return {
      src: "",
      imgSrc: null,
      listPdf: 3,
      pages: 0,
      numPages: 0
    };
  },
  watch: {
    pdfSrc() {
      this.showPdf(this.pdfSrc);
    }
  },
  methods: {
    showPdf(file) {
      if (file.type === "image") {
        this.src = null;
        this.numPages = 1;
        let reader = new FileReader();
        reader.readAsDataURL(file.src);
        reader.onloadend = () => {
          this.imgSrc = reader.result;
        };
      } else {
        this.imgSrc = null;
        this.src = pdf.createLoadingTask(file.src);
        this.src.promise.then(pdf => {
          this.numPages = pdf.numPages;
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.pdf-viewer {
  margin: 0 10px;

  &__pdf {
    background-color: #fafafa;
    margin: 1rem;
    padding: 1rem;
    border: 2px solid #ccc;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  &__img {
    width: 100%;
  }
}

img {
  cursor: pointer;
  width: 50%;
}

.spinner-container {
  display: flex;
  justify-content: center;
}
</style>
