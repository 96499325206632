<template>
  <div class="info-email">
    <Splitter class="splitters-content">
      <SplitterPanel :size="75" class="p-d-flex p-ai-center p-jc-center">
        <div class="splitter-form">
          <div class="splitter-form__content" :disabled="true">
            <Form
              @renderBucketFiles="renderBucketFiles"
              @statusPending="statusPending"
            />
          </div>
        </div>
      </SplitterPanel>
      <SplitterPanel
        :size="35"
        class="p-d-flex p-ai-center p-jc-center"
        :key="renderSplitterAttachments"
      >
        <div class="splitter-attachments-viewer">
          <div
            class="splitter-attachments-viewer__content"
            v-if="status == null"
          >
            <InfoEmailAttachments
              @getPdfSrc="getPdfSrc"
              :bucketFiles="bucketFiles"
            />
            <PdfViewer :pdfSrc="file" />
          </div>
          <div v-else class="attachments-pending">
            <center>
              <i class="far fa-file fa-2x"></i>
              <strong> No existen archivos para mostrar </strong>
            </center>
          </div>
        </div>
      </SplitterPanel>
    </Splitter>
  </div>
</template>

<script>
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Form from "./form/Form";
import PdfViewer from "./form/PdfViewer";
import InfoEmailAttachments from "./form/InfoEmailAttachments.vue";

export default {
  name: "InfoEmail",
  components: {
    Splitter,
    SplitterPanel,
    Form,
    PdfViewer,
    InfoEmailAttachments,
  },
  data() {
    return {
      bucketFiles: [],
      file: null,
      renderSplitterAttachments: 5,
      renderPdfViewer: 15,
      status: null,
    };
  },
  methods: {
    async renderBucketFiles(files) {
      this.bucketFiles = files;
      this.renderSplitterAttachments += 1;
    },
    getPdfSrc(file) {
      this.file = file;
      this.renderPdfViewer += 1;
      this.renderSplitterAttachments += 1;
    },
    statusPending(value) {
      this.status = value;
    },
  },
};
</script>

<style lang="scss" scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.splitter-form {
  width: 100%;
  @extend %splitter-content;

  &__content {
    margin: 20px 15px 0px 0px;
  }
}

.splitter-attachments-viewer {
  width: 100%;
  @extend %splitter-content;

  &__content {
    margin: 20px 1% 0px 15px;
  }
}

.splitters-content {
  height: 100vh;
}

::v-deep .p-splitter {
  border: none !important;
}

.attachments-pending {
  padding: 100px;
}
</style>
