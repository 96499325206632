<template>
  <div class="payable-lines-table">
    <h5 class="payable-lines-table__title text-center">
      <strong>PAYABLE LINES</strong>
    </h5>
    <b-table
      id="payables-table"
      show-empty
      hover
      fixed
      class="payable-lines-table__table"
      head-variant="light"
      stacked="md"
      :items="items"
      :fields="fields"
      :busy="isBusy"
    >
      <template v-slot:table-busy>
        <div class="spinner-payables-line">
          <b-spinner
            v-if="isBusy"
            style="width: 3rem; height: 3rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </div>
      </template>
      <template v-slot:cell(name)="row">
        <input
          type="text"
          readonly
          v-model="items[row.index].name"
          :title="items[row.index].name"
          class="table-input"
        />
      </template>
      <template v-slot:cell(product)="row">
        <input
          type="text"
          readonly
          v-model="items[row.index].product"
          :title="items[row.index].product"
          class="table-input"
        />
      </template>
      <template v-slot:cell(expense_gi_account)="row">
        <input
          type="text"
          readonly
          v-model="items[row.index].expense_gi_account"
          :title="items[row.index].expense_gi_account"
          class="table-input"
        />
      </template>
      <template v-slot:cell(amount)="row">
        <input
          :disabled="statusPayable != 'Waiting for Approval'"
          type="number"
          v-model="items[row.index].acctseed_amount"
          :title="items[row.index].amount"
          class="table-input"
          @blur="updatePayablesLine(items[row.index], row.index)"
        />
      </template>
    </b-table>
    <div class="button" v-if="statusPayable == 'Waiting for Approval'">
      <b-button pill variant="info" @click="newPayment">Send to TMS</b-button>
    </div>
  </div>
</template>

<script>
import fieldsPayableLinesTable from "@/utils/payableLinesTable";

export default {
  name: "PayableLinesTable",
  props: {
    payablesId: {
      type: Object,
      required: true,
    },
    shP: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      itemsAux: null,
      fields: null,
      isBusy: false,
      colspan: 4,
      statusPayable: null,
    };
  },
  created() {
    this.fields = fieldsPayableLinesTable;
    this.statusPayable = this.payablesId.acctseed_status;
    this.showPayablesLine();
  },
  methods: {
    async showPayablesLine() {
      this.isBusy = true;
      let response = await this.$store.dispatch(
        "crmPayablesLine/getPayableLines",
        this.payablesId.id
      );
      this.items = response.data;
      this.itemsAux = JSON.parse(JSON.stringify(this.items));
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].product = response.data[i].acctseed_product.name;
        this.items[i].expense_gi_account =
          response.data[i].acctseed_expense_gl_account.name;
      }
      this.isBusy = false;
    },
    async updatePayablesLine(item, index) {
      if (this.itemsAux[index].acctseed_amount !== item.acctseed_amount) {
        this.$emit("totalChanged");
        await this.$store.dispatch("crmPayablesLine/updatePayablesLine", {
          payable_line_id: item.id,
          payable_db_id: this.$store.getters["dbPayables/showSetPayables"],
          body: {
            acctseed_amount: parseInt(item.acctseed_amount),
          },
        });
        this.itemsAux = JSON.parse(JSON.stringify(this.items));
        this.$emit("updateTotals", this.rowIndex);
      }
    },
    async newPayment() {
      await this.$store.dispatch("crmPayables/newPayment", {
        body: {
          payable_db_id: this.$store.getters["dbPayables/showSetPayables"],
          payable_crm_id: this.payablesId.id,
          email_id: this.$route.params.id,
          payable_crm_name: this.payablesId.name,
          shp: this.shP.shp,
        },
      });
      this.$emit("showDataPayables", "PayablesTable");
      this.showPayablesLine();
    },
  },
};
</script>

<style lang="scss" scoped>
.payable-lines-table {
  &__title {
    color: $color-background-dark;
  }
  &__table {
    margin-top: 20px;
    @include box-shadow;
  }
}

.b-table {
  font-size: 11px;
}

.table-input {
  width: 100%;
  border: none;
  outline: none;
  color: inherit;
  border-radius: 6px;
  padding: 5px 3px;
}

.button {
  display: flex;
  justify-content: center;
  width: 100%;
  justify-content: flex-end;
}

.spinner-payables-line {
  display: flex;
  justify-content: center;
}
</style>
