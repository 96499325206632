<template>
  <div class="email-response">
    <b-overlay :show="isLoading" rounded="sm">
      <Message severity="error" v-if="isError">{{ msgError }}</Message>
      <b-tabs>
        <b-tab title="DISPUTES" active class="email-response__tab">
          <Receivers
            :emailInfo="emailInformation"
            :emailType="'disputes'"
            @custom-change="changeReceivers"
          />
          <FroalaEditor v-model="disputes" />
          <Signature v-model="signature" />
          <div class="form__submit">
            <b-button pill class="submit-button" @click="sendEmailDispute()"
              >Send Dispute <i class="fas fa-paper-plane"></i
            ></b-button>
          </div>
        </b-tab>
        <b-tab title="TO CARRIER" class="email-response__tab">
          <Receivers
            :emailInfo="emailInformation"
            :emailType="'carrier'"
            @custom-change="changeReceivers"
          />
          <FroalaEditor v-model="toCarrier" />
          <Signature v-model="signature" />
          <div class="form__submit">
            <b-button pill class="submit-button" @click="sendEmailCarrier()"
              >Send to Carrier <i class="fas fa-paper-plane"></i
            ></b-button>
          </div>
        </b-tab>
      </b-tabs>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="envelope" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label"><b>Sending Email...</b></p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import Receivers from "./Receivers.vue";
import FroalaEditor from "./FroalaEditor.vue";
import Signature from "./Signature.vue";
import Message from "primevue/message";
import JSSoup from "jssoup";
import signature from "@/assets/images/signature-glt-base64.js";

export default {
  name: "EmailResponse",
  props: {
    emailInformation: {
      type: Object,
    },
  },
  components: {
    Receivers,
    FroalaEditor,
    Signature,
    Message,
  },
  data() {
    return {
      formDataDisputes: null,
      formDataCarrier: null,
      soupHtml: null,
      isLoading: false,
      isError: false,
      msgError: "",
      disputes: "",
      toCarrier: "",
      signature: null,
      emailId: null,
      infoDisputes: {
        sender: "carrierpay@shipwithglt.com",
        receiver: [],
        cc: [],
        bcc: [],
        emailSubject: "",
      },
      infoCarrier: {
        sender: "carrierpay@shipwithglt.com",
        receiver: [],
        cc: [],
        bcc: [],
        emailSubject: "",
      },
    };
  },
  created() {
    this.emailId = this.$route.params.id;
  },
  methods: {
    changeReceivers(data) {
      if (data.type == "disputes") {
        this.infoDisputes = data.info;
      }

      if (data.type == "carrier") {
        this.infoCarrier = data.info;
      }
    },

    scrollTop() {
      let scrollTop = document.getElementById("scroll-top-email");
      scrollTop.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },

    validationDispute() {
      if (!this.infoDisputes.receiver.length > 0) {
        this.scrollTop();
        this.isError = true;
        this.msgError = "Please enter the receivers in DISPUTES";
        return false;
      } else {
        this.isError = false;
        this.msgError = "";
        return true;
      }
    },

    validationCarrier() {
      if (!this.infoCarrier.receiver.length > 0) {
        this.scrollTop();
        this.isError = true;
        this.msgError = "Please enter the receivers in TO CARRIER";
        return false;
      } else {
        this.isError = false;
        this.msgError = "";
        return true;
      }
    },

    async sendEmailDispute() {
      if (this.validationDispute()) {
        this.formDataDisputes = new FormData();
        let contentDisputes = this.disputes + this.signature;
        /*CATCH BASE64 IMGS SRC, CONVERT TO BLOB AND SEND AS ATTACHMENTS*/
        this.soupHtml = new JSSoup(contentDisputes);
        let base64Images = this.soupHtml.findAll("img");
        if (base64Images.length > 0) {
          await this.convertImages(base64Images, "formDataDisputes");
        }
        /*DATA FOR DISPUTES*/
        this.formDataDisputes.append(
          "receivers",
          this.infoDisputes.receiver.join()
        );
        this.formDataDisputes.append("subject", this.infoDisputes.emailSubject);
        this.formDataDisputes.append("mail_content", this.soupHtml.toString());

        this.formDataDisputes.append(
          "references",
          this.emailInformation.uid_msg
        );
        this.formDataDisputes.append("reply_to", this.emailInformation.uid_msg);
        this.formDataDisputes.append("email_id", this.emailId);

        this.infoDisputes.files.forEach((file) => {
          this.formDataDisputes.append("attachment_files", file);
        });

        /*VERIFY CC AND BCC*/
        if (this.infoDisputes.cc.join() != "") {
          this.formDataDisputes.append("cc", this.infoDisputes.cc.join());
        }
        if (this.infoDisputes.bcc.join() != "") {
          this.formDataDisputes.append("bcc", this.infoDisputes.bcc.join());
        }
        /*SEND EMAIL*/
        this.isLoading = true;
        await this.$store.dispatch("emails/sendEmail", {
          body: this.formDataDisputes,
        });
        this.isLoading = false;
        this.$router.push({ path: "/payments/ocr-processes/" });
      }
    },
    async sendEmailCarrier() {
      if (this.validationCarrier()) {
        let contentCarrier = this.toCarrier + this.signature;
        this.formDataCarrier = new FormData();
        /*CATCH BASE64 IMGS SRC, CONVERT TO BLOB AND SEND AS ATTACHMENTS*/
        this.soupHtml = new JSSoup(contentCarrier);
        let base64Images = this.soupHtml.findAll("img");
        if (base64Images.length > 0) {
          await this.convertImages(base64Images, "formDataCarrier");
        }
        /*DATA FOR CARRIER*/
        this.formDataCarrier.append(
          "receivers",
          this.infoCarrier.receiver.join()
        );
        this.formDataCarrier.append("subject", this.infoCarrier.emailSubject);
        this.formDataCarrier.append("mail_content", this.soupHtml.toString());
        this.formDataCarrier.append(
          "references",
          this.emailInformation.uid_msg
        );
        this.formDataCarrier.append("reply_to", this.emailInformation.uid_msg);
        this.formDataCarrier.append("email_id", this.emailId);

        this.infoCarrier.files.forEach((file) => {
          this.formDataCarrier.append("attachment_files", file);
        });

        /*VERIFY CC AND BCC*/
        if (this.infoCarrier.cc.join() != "") {
          this.formDataCarrier.append("cc", this.infoCarrier.cc.join());
        }
        if (this.infoCarrier.bcc.join() != "") {
          this.formDataCarrier.append("bcc", this.infoCarrier.bcc.join());
        }

        /*SEND EMAIL*/
        this.isLoading = true;
        await this.$store.dispatch("emails/sendEmail", {
          body: this.formDataCarrier,
        });
        this.isLoading = false;
        this.$router.push({ path: "/payments/ocr-processes/" });
      }
    },
    async convertImages(imgSrcs, formDataType) {
      for (let img of imgSrcs) {
        let imageGUID = this.createGUID();
        let file = await this.convertImageToBlob();
        this[formDataType].append(
          "attachment_files",
          file,
          `image${imageGUID}.png`
        );
        img.attrs.src = `cid:image${imageGUID}.png`;
      }
    },
    createGUID() {
      return "_" + Math.random().toString(36).substr(2, 9);
    },
    async convertImageToBlob() {
      const byteCharacters = atob(signature);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },
  },
};
</script>

<style lang="scss" scoped>
.email-response {
  margin-top: 30px;
  @include card;
  padding: 20px;

  &__tab {
    padding: 20px 10px;
  }
}

.form {
  &__submit {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.submit-button {
  @include primary-button;
}
</style>
