<template>
  <div class="froala-editor-component">
    <froala
      id="froala-editor"
      :config="config"
      :tag="'textarea'"
      v-model="replyText"
    >
    </froala>
  </div>
</template>

<script>
export default {
  name: "FroalaEditor",
  data() {
    return {
      replyText: "",
      config: {
        heightMin: 250,
        heightMax: 600,
        charCounterCount: false,
        quickInsertEnabled: false,
        key:
          "iTB2xB2B1A4C1B3C1wd1DBKSPF1WKTUCQOa1OURPJ1KDe2F-11D2C2D2G2C3B3D2D6D1F1==",
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "fontFamily",
              "fontSize",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting"
            ],
            align: "left",
            buttonsVisible: 3
          },
          moreParagraph: {
            buttons: [
              "alignLeft",
              "alignCenter",
              "formatOLSimple",
              "alignRight",
              "alignJustify",
              "formatOL",
              "formatUL",
              "paragraphFormat",
              "paragraphStyle",
              "lineHeight",
              "outdent",
              "indent",
              "quote"
            ],
            align: "left",
            buttonsVisible: 3
          },
          moreRich: {
            buttons: [
              "insertTable",
              "emoticons",
              "fontAwesome",
              "specialCharacters",
              "embedly",
              "insertHR"
            ],
            align: "left",
            buttonsVisible: 5
          },
          moreMisc: {
            buttons: [
              "undo",
              "redo",
              "fullscreen",
              "print",
              "spellChecker",
              "selectAll",
              "html"
            ],
            align: "right",
            buttonsVisible: 2
          }
        }
      }
    };
  },
  watch: {
    replyText() {
      this.$emit("input", this.replyText);
    }
  },
  beforeDestroy() {
    this.replyText = "";
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  & .fr-box.fr-basic .fr-wrapper,
  .fr-toolbar.fr-top {
    border: none;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  & .fr-second-toolbar {
    display: none;
  }

  & .fr-toolbar .fr-command.fr-btn svg.fr-svg,
  .fr-popup .fr-command.fr-btn svg.fr-svg,
  .fr-modal .fr-command.fr-btn svg.fr-svg {
    height: 17px;
  }

  & .fr-toolbar .fr-command.fr-btn i,
  .fr-toolbar .fr-command.fr-btn svg,
  .fr-popup .fr-command.fr-btn i,
  .fr-popup .fr-command.fr-btn svg,
  .fr-modal .fr-command.fr-btn i,
  .fr-modal .fr-command.fr-btn svg {
    display: block;
    text-align: center;
    float: none;
    margin: 3px 2px;
    width: 17px;
  }
}
</style>
